<script setup>
const props = defineProps({
  rating: {
    type: String,
    required: true,
  },
  percentage: {
    type: Number,
    required: true,
  },
  score: {
    type: Number,
    required: true,
  },
});

const validPercentage = computed(() => {
  return Math.min(Math.max(Number.parseFloat((props.percentage * 100) / 10).toFixed(0), 0), 100);
});

const validScore = computed(() => {
  return Math.min(Math.max(props.score, 0), 10);
});
</script>

<template>
  <div class="rating-breakdown">
    <div class="rating">
      {{ props.rating }}
    </div>
    <div class="progressbar-border">
      <div
        class="progressbar-fill"
        :style="{ width: `${validPercentage}%` }"
      />
    </div>
    <div class="score">
      <strong> {{ validScore.toFixed(1) }} </strong>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.rating-breakdown {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $wds-color-ink-darker;
  padding-bottom: $wds-spacing-s;

  @include body-1-reg;

  .rating {
    width: 50%;
  }

  .progressbar-border {
    flex-grow: 1;
    height: $wds-spacing-s;
    background-color: $wds-color-ink-lightest;
    border-radius: $wds-border_radius-s;
  }

  .progressbar-fill {
    background-color: $wds-color-sunset-light;
    height: $wds-spacing-s;
    border-radius: $wds-border_radius-s;
  }

  .score {
    width: 10%;
    margin-left: $wds-spacing-s;
  }
}
</style>
