<script setup>
import { WdsButtonLink, WdsRating } from '@wds/components';

const props = defineProps({
  cityName: {
    type: String,
    required: true,
  },
  cityDescription: {
    type: String,
    required: true,
  },
  ratingBreakdown: {
    type: Object,
    required: true,
  },
});

const { isSmallOrMediumScreen } = useMq();
const { useRating } = useRatings();

const isOpen = ref(false);

function toggleRatingBreakdown() {
  isOpen.value = !isOpen.value;
}
function getRatingsNames(ratings) {
  return Object.keys(ratings).filter((val) => {
    return val !== 'averageRating' && val !== 'numberOfReviews';
  });
}

const hasValidRating = computed(() => {
  return props.ratingBreakdown?.averageRating;
});

const getCityInfoDescription = computed(() => {
  const transcodeInterpolate = new TranscodeInterpolate();
  return transcodeInterpolate.interpolate(props.cityDescription, { CHOSENCITY: props.cityName });
});
</script>

<template>
  <section
    class="about-city"
    :class="{ 'no-rating': !hasValidRating }"
  >
    <div class="rating-container" />
    <div
      class="rating-wrapper"
      :class="isSmallOrMediumScreen && !isOpen ? 'collapsed' : ''"
    >
      <h3
        class="title"
        v-html="$t('t_ABOUTLOCATION', { LOCATION: `<span>${cityName}</span>` })"
      />
      <div class="rating-card">
        <div class="rating-score">
          <WdsRating
            class="rating-score-summary"
            size="large"
            direction="column"
            :total-reviews="parseFloat(ratingBreakdown.numberOfReviews)"
            :score="parseFloat(ratingBreakdown.averageRating)"
            :translations="useRating.getRatingScoreKeywordTranslations"
          />
          <WdsButtonLink
            class="rating-breakdown-toggler"
            :icon-start="isOpen ? 'chevron-up' : 'chevron-down'"
            @click.prevent="toggleRatingBreakdown()"
          />
        </div>
        <div class="rating-breakdown">
          <div
            v-for="(rating, id) in getRatingsNames(ratingBreakdown)"
            :key="id"
            class="rating-breakdown-category"
          >
            <CityHwAboutSectionRatingBreakdown
              :rating="$t(`t_RATING${rating.toUpperCase()}`)"
              :percentage="parseFloat(ratingBreakdown[rating])"
              :score="parseFloat(ratingBreakdown[rating])"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="description-container">
      <h3
        class="title"
        v-html="$t('t_ABOUTLOCATION', { LOCATION: `<span>${cityName}</span>` })"
      />
      <div
        class="description-text"
        v-html="getCityInfoDescription"
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.about-city {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $wds-color-white;

  h3.title {
    @include title-3-bld;

    text-align: left;
    margin-bottom: $wds-spacing-l;
    color: $wds-color-ink-darker;

    :deep(span) {
      color: $wds-color-purple;
    }
  }

  .rating-container {
    display: none;
  }

  .rating-wrapper {
    .rating-card {
      position: relative;
      margin-bottom: $wds-spacing-xl;

      .rating-score {
        display: inline-flex;
        align-items: center;
        gap: $wds-spacing-s;
        padding-bottom: $wds-spacing-m;
      }
    }

    &.collapsed {
      .rating-card {
        .rating-breakdown {
          overflow-y: hidden;
          max-height: 9rem;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: wds-rem(32px);
          background: linear-gradient(180deg, rgb(255 255 255 / 0%) 0%, $wds-color-white 100%);
        }
      }
    }
  }

  .description-container {
    > h3.title {
      display: none;
    }

    :deep(.description-text) {
      @include body-1-reg;

      p {
        text-align: left;
        margin-bottom: $wds-spacing-m;
      }

      a {
        @include body-1-bld;

        color: $wds-color-ink-dark;
        text-decoration: underline;
        outline: none;
        padding: $wds-spacing-s 0;
        background-color: transparent;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
          color: $wds-color-ink;
        }

        &:focus-visible {
          box-shadow:
            0 0 0 $wds-spacing-xxs $wds-color-white,
            0 0 0 $wds-spacing-xs $wds-color-ink-darker;
        }
      }
    }
  }

  &.no-rating {
    .rating-wrapper .rating-card {
      display: none;
    }
  }

  @include desktop {
    flex-direction: row;

    .rating-container {
      display: block;
      position: relative;
      width: wds-rem(408px);
      min-height: wds-rem(640px);
      background-color: $wds-color-purple;
      clip-path: polygon(0 0, 100% 0%, calc(100% - $wds-spacing-xxxl) 100%, 0% 100%);
    }

    .rating-wrapper {
      position: absolute;
      top: $wds-spacing-l;
      left: wds-rem(40px);
      bottom: $wds-spacing-l;
      width: wds-rem(408px);
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      > h3.title {
        display: none;
      }

      .rating-card {
        display: flex;
        flex-direction: column;
        gap: $wds-spacing-m;
        background: $wds-color-white;
        box-shadow: $wds-shadow-light_s;
        border-radius: $wds-border-radius-xl;
        padding: $wds-spacing-l;
        min-width: wds-rem(288px);
        position: sticky;
        top: wds-rem(120px);
        z-index: 1;

        .rating-score .rating-breakdown-toggler {
          display: none;
        }

        &::after {
          display: none;
        }
      }
    }

    .description-container {
      position: relative;
      flex: 1;
      padding: $wds-spacing-l $wds-spacing-xxl;
      background-color: $wds-color-white;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -63px;
        bottom: 0;
        width: 64px;
        background-color: $wds-color-white;
        clip-path: polygon(99% 0, 100% 0, 100% 100%, 0 100%);
      }

      h3.title {
        @include title-1-bld;

        display: block;
      }
    }

    &.no-rating {
      .rating-container,
      .rating-wrapper {
        display: none;
      }

      .description-container {
        padding: 0;
      }
    }
  }
}
</style>
